import styled from 'styled-components'
import {device} from '../../../theme'

export const RecentPostWrap = styled.section `
    margin-bottom: 50px;
    ${device.medium}{
        margin-bottom: 0;
    }
`;

export const RecentPostContent = styled.div `
    
`;